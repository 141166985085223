// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";



.b-overlay {
  height: 100vh;
}

.spinner-border {
  width: 3.5rem !important;
  height: 3.5rem !important;
}

